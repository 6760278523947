.container {
  background: var(--white);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  padding: 24px 20px;
  width: calc(375px - 36px - 36px);
}

.confirmTitle {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 8px;
}

.kasaInfo {
  align-self: center;
  margin-bottom: 15px;
}

.title {
  font-weight: 700;
  margin-bottom: 10px;
  text-align: center;
}

.text {
  margin-bottom: 15px;
}

.label {
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  justify-content: center;
  margin-bottom: 15px;
  padding: 8px 0;
  white-space: pre-line;
}

.checkbox {
  margin-right: 15px;
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
}

.button {
  background: var(--primary-dark-blue);
  border: 1px solid var(--primary-dark-blue);
  border-radius: 20px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  color: var(--white);
  font-weight: 700;
  height: 40px;
  width: 124px;
}

.submitButton {
  margin-bottom: 15px;
  width: 100%;
}

.button[disabled] {
  background: var(--cloud-grey);
  border: 1px solid var(--hint);
  box-shadow: none;
  color: var(--hint);
}

.faqButton {
  background: var(--white);
  border: 1px solid var(--primary-dark-blue);
  color: var(--primary-dark-blue);
}
