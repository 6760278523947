.container {
  background: var(--white);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 270px;
}

.closeButton {
  position: absolute;
  right: 8px;
  top: 8px;
}

.header {
  color: var(--white);
  display: flex;
  flex-direction: column;
  height: 76px;
  padding: 8px;
  padding-right: calc(2px + 24px + 8px);
  position: relative;
}

.headerImage {
  border-radius: 15px 15px 0 0;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.headerImageMask {
  background: var(--primary-dark-blue);
  border-radius: 15px 15px 0 0;
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.headerImageMaskRed {
  background: #5a0000;
}

.name {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 2px;

  /* TODO: use @mixin */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text {
  font-size: 12px;
}

.footer {
  background: var(--white);
  border-radius: 0 0 15px 15px;
  display: flex;
  height: 52px;
  justify-content: center;
  flex-direction: column;
  padding: 7px 10px;
}

.count {
  align-items: center;
  display: flex;
  font-size: 16px;
}

.countIcon {
  margin: 0 6px;
}

.countHint {
  font-weight: 700;
  margin-right: 16px;
}

.mainenance {
  align-items: center;
  background: rgba(255, 74, 107, 0.05);
  border-radius: 19px;
  color: var(--alert-red);
  display: flex;
  padding: 7px 5px;
}

.mainenanceHintLarge {
  font-size: 16px;
  font-weight: 700;
  margin-left: 10px;
}

.mainenanceHintSmall {
  font-size: 10px;
  margin-left: 16px;
  white-space: pre-line;
}
