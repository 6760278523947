.page {
  padding: 16px 30px 0;
}

.title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.text {
  margin-bottom: 20px;
  white-space: pre-line;
}

.priceCard {
  margin-bottom: 20px;
}

.changeButton {
  align-items: center;
  background: var(--primary);
  color: var(--white);
  font-size: 18px;
  font-weight: 700;
  display: flex;
  height: 80px;
  justify-content: center;
  margin: 0 -30px;
  margin-top: auto;
}
