.page {
  padding: 24px 16px;
}

.page {
  padding: 24px 16px;
}

.monthItem {
  margin-bottom: 30px;
}

.month {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.item {
  margin-bottom: 10px;
}

.historyItem {
  border: 1px solid var(--primary);
  border-radius: 10px;
  overflow: hidden;
}

.historyItemUsing {
  border-color: var(--orange);
}

.historyItemHeader {
  background: var(--primary);
  color: var(--white);
  display: flex;
  height: 35px;
}

.historyItemUsing .historyItemHeader {
  background: var(--orange);
}

.historyItemHeaderDate {
  align-items: center;
  background: var(--primary-dark-blue);
  display: flex;
  font-size: 12px;
  justify-content: center;
  width: 60px;
}

.historyItemHeaderText {
  font-size: 20px;
  font-weight: 700;
  padding: 0 12px;
}

.historyItemBody {
  align-items: center;
  display: flex;
  padding: 8px;
}

.historyItemIconContainer {
  align-items: center;
  background: var(--primary);
  border-radius: 10px;
  color: var(--white);
  display: flex;
  flex-shrink: 0;
  height: 50px;
  justify-content: center;
  margin-right: 8px;
  width: 50px;
}

.historyItemUsing .historyItemIconContainer {
  background: var(--orange);
}

.historyPosition {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100px;
}

.historyPositionEnd {
  align-items: flex-end;
}

.historyPositionIcon {
  margin: 0 24px;
  flex-shrink: 0;
}

.historyName {
  font-weight: 700;
  /* TODO: use @mixin */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
