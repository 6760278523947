.root {
  align-items: center;
  display: flex;
  height: 100vh;
  left: 0;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100vw;
}

.container {
  position: relative;
}

.closeButton {
  color: var(--white);
  left: 16px;
  position: absolute;
  top: 16px;
  z-index: 1;
}
