.container {
  border: 1px solid var(--primary);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 72px;
}

.kasa {
  display: flex;
  flex-grow: 1;
  min-height: 58px;
}

.date {
  align-items: center;
  background: var(--primary);
  display: flex;
  color: var(--white);
  font-weight: 700;
  padding: 0 16px;
}

.dateIcon {
  margin-right: 10px;
}

.info {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding: 0 14px;
}

.id {
  color: rgba(0, 0, 0, 0.29);
  font-size: 12px;
}

.pin {
  align-items: flex-start;
  font-size: 18px;
  display: flex;
  margin-top: 3px;
}

.pinIcon {
  margin-top: 3px;
  margin-right: 14px;
}
