.container {
  align-items: center;
  background: var(--white);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  width: 300px;
}

.title {
  font-size: 18px;
  font-weight: 700;
  margin-top: 17px;
}

.contentHint {
  margin-top: 16px;
}

.content {
  margin-top: 32px;
  white-space: pre-line;
}

.readErrorKasaImage {
  height: 101px;
  width: 243px;
}

.tryButton {
  align-self: stretch;
  background: var(--white);
  border: 1px solid var(--secondary-blue);
  border-radius: 19px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  color: var(--secondary-blue);
  font-weight: 700;
  margin-top: 32px;
  height: 42px;
}

.bottomButtonContainer {
  align-items: center;
  align-self: stretch;
  display: flex;
  margin-top: 20px;
}

.plainButton {
  font-weight: 700;
  height: 40px;
}

.plainButton:first-child {
  margin-right: auto;
}
