.page {
  padding: 40px 16px;
}

.hint {
  font-weight: 700;
}

.howToButton {
  background: var(--primary);
  border-radius: 25px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  color: var(--white);
  font-weight: 700;
  height: 50px;
  margin-top: 30px;
  width: 200px;
}

.sectionTitle {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: 40px;
}

.questionItem {
  border-radius: 2px;
  box-shadow: 0px 1px 4px rgba(50, 72, 123, 0.2);
  margin-bottom: 16px;
}

.questionHeader {
  align-items: center;
  display: flex;
  font-weight: 700;
  padding: 16px 24px 24px 8px;
}

.questionTitle {
  margin-right: 16px;
  white-space: pre-line;
}

.questionHeaderIcon {
  color: var(--primary-light);
  flex-shrink: 0;
  margin-left: auto;
}

.questionAnswer {
  margin-top: 16px;
  padding: 8px;
  white-space: pre-line;
}
