.container {
  align-items: center;
  background: var(--secondary-blue);
  border-radius: 30px 30px 0 0;
  bottom: 0;
  color: var(--white);
  display: flex;
  flex-direction: column;
  left: 0;
  padding-bottom: env(safe-area-inset-bottom);
  padding-top: 40px;
  position: fixed;
  width: 100%;
}

.hint {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 16px;
}

.buttonGroup {
  display: flex;
}

.button {
  align-items: center;
  background: var(--white);
  border-radius: 10px;
  color: var(--secondary-blue);
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 700;
  height: 130px;
  justify-content: center;
  left: 0;
  margin-bottom: 50px;
  padding: 8px;
  position: relative;
  top: 0;
  white-space: pre-line;
  width: 130px;
}

.button:first-child {
  margin-right: 20px;
}

.buttonDisableMask {
  align-items: center;
  background: var(--dark-grey);
  border-radius: 10px;
  color: var(--white);
  display: flex;
  font-size: 14px;
  height: 100%;
  justify-content: center;
  opacity: 0.9;
  padding: 8px;
  position: absolute;
  width: 100%;
}
