.page {
  padding: 48px 16px 24px;
}

.title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 24px;
}

.card {
  margin-bottom: 21px;
}

.card:last-child {
  margin-bottom: 0;
}
