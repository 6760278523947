.container {
  background: var(--white);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: calc(375px - 36px - 36px);
}

.title {
  border-bottom: 1px solid var(--primary-dark-blue);
  font-size: 14px;
  font-weight: 700;
  padding: 20px 0 8px;
  text-align: center;
}

.text {
  font-size: 18px;
  font-weight: 700;
  margin-top: 20px;
  padding: 20px;
  white-space: pre-line;
}

.text strong {
  font-size: 26px;
}

.image {
  display: block;
  width: 100%;
}

.shareContainer {
  justify-content: space-evenly;
  display: flex;
  margin-top: 20px;
}

.shareButton {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 700;
}

.shareButtonImage {
  height: 56px;
  margin-bottom: 3px;
  width: 56px;
}

.closeButton {
  background: var(--primary-dark-blue);
  color: var(--white);
  font-size: 18px;
  font-weight: 700;
  margin-top: 32px;
  padding: 16px 0;
  width: 100%;
}
