.page {
  height: 100vh;
}

.page0 {
  background: var(--primary);
  color: var(--white);
}

.page0 .imageContainer {
  padding-top: 16px;
}

.page1 {
  background: var(--alert-red);
  color: var(--white);
}

.page1 .imageContainer {
  background: var(--white);
}

.page2 {
  background: var(--accent);
  color: var(--primary-dark-blue);
}

.page2 .imageContainer {
  background: var(--white);
}

.imageContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  max-height: 224px;
  justify-content: center;
  overflow: hidden;
}

.image {
  height: 100%;
}

.textContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 1;
  margin-bottom: env(safe-area-inset-bottom);
  justify-content: center;
  padding: 24px 16px;
}

.title {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  white-space: pre-line;
}

.text {
  font-size: 20px;
  font-weight: 700;
  margin-top: 16px;
  text-align: center;
  white-space: pre-line;
}

.indicator {
  margin-top: 16px;
}

.nextButton {
  background: var(--primary-dark-grey);
  border-radius: 25px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  color: var(--primary-dark-blue);
  font-weight: 700;
  height: 50px;
  margin-top: 16px;
  width: 262px;
}

.skipButton {
  color: var(--primary-dark-blue);
  font-size: 12px;
  margin-top: 16px;
}
