.page {
  padding: 16px;
}

.noRenting {
  align-items: center;
  background: var(--primary);
  border-radius: 10px;
  color: var(--white);
  display: flex;
  font-weight: 700;
  height: 70px;
  justify-content: center;
  margin-bottom: 16px;
}

.kasaList {
  margin-bottom: 16px;
}

.trySubscriptionPlan {
  align-items: center;
  background: var(--accent);
  border-radius: 26px;
  box-shadow: 0px 1px 4px rgba(50, 72, 123, 0.2);
  color: var(--primary-dark-blue);
  display: flex;
  font-weight: 700;
  height: 52px;
  justify-content: center;
  margin-bottom: 16px;
  padding: 0 16px;
}

.trySubscriptionPlanIcon {
  margin-left: auto;
}

.topMenu {
  margin-bottom: 16px;
}
