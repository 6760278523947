/* TODO: many color in this page not using var */
.root {
  align-self: flex-start;
  margin: 0 16px;
  margin-top: calc(16px + 24px + 16px);
  padding-bottom: 48px;
}

.container {
  background: #f9f9f9;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 30px 16px;
}

.title {
  color: var(--primary-dark-blue);
  font-size: 18px;
  font-weight: 700;
  margin-top: 17px;
}

.titleHint {
  margin-top: 16px;
  margin-bottom: 6px;
}

.step {
  align-items: center;
  background: var(--white);
  border-radius: 10px;
  display: flex;
  font-weight: 700;
  margin-top: 10px;
  padding: 8px;
}

.stepIndex {
  font-size: 36px;
  margin-right: 9px;
  text-align: center;
  width: 42px;
}

.tryButton {
  align-items: center;
  background: var(--secondary-blue);
  border-radius: 25px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  color: var(--white);
  display: flex;
  font-weight: 700;
  justify-content: center;
  margin-top: 16px;
  height: 50px;
}

.tryButtonIcon {
  margin-right: 14px;
}

.helpHint {
  font-weight: 700;
  margin-top: 40px;
  white-space: pre-line;
}

.buttonGroup {
  display: flex;
  flex-direction: column;
}

.helpButton {
  background: var(--white);
  border: 1px solid #d0d0d0;
  border-radius: 25px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  color: var(--primary-dark-blue);
  font-weight: 700;
  margin-top: 32px;
  height: 50px;
}

.homeButton {
  font-weight: 700;
  margin-top: 16px;
  height: 43px;
}
