.container {
  background: var(--white);
  border-radius: 30px;
  padding: 24px 20px;
  width: calc(375px - 36px - 36px);
}

.contentContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  text-align: center;
}

.title {
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  white-space: pre-line;
}

.content {
  align-self: stretch;
  margin-top: 16px;
  white-space: pre-line;
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
}

.button {
  margin-right: 16px;
  width: 50%;
}

.confirmButton {
  margin-right: 0;
}

.icon {
  margin-right: 10px;
}

.singleButton {
  margin-right: 0;
  flex-grow: 1;
}
