.page {
  padding: 24px 16px;
}

.amountList {
  display: flex;
  margin-bottom: 10px;
}

.amountItem {
  border: 1px solid var(--primary);
  border-radius: 10px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  font-weight: 700;
  overflow: hidden;
  width: 50%;
}

.amountItem:first-child {
  margin-right: 24px;
}

.amountTitle {
  align-items: center;
  background: var(--primary);
  display: flex;
  color: var(--white);
  justify-content: center;
  height: 40px;
}

.amountContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 60px;
  justify-content: center;
  padding: 4px 0;
}

.amountBody {
  font-size: 20px;
}

.amountFooter {
  font-size: 12px;
}

.trySubscriptionPlan {
  align-items: center;
  background: var(--accent);
  border-radius: 10px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  color: var(--primary-dark-blue);
  display: flex;
  height: 61px;
  justify-content: center;
  margin-bottom: 10px;
  padding: 0 8px;
}

.trySubscriptionText {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.trySubscriptionTextTitle {
  font-weight: 700;
}

.trySubscriptionTextHint {
  font-size: 12px;
}

.trySubscriptionPlanIcon {
  margin-left: auto;
}
