.container {
  width: calc(375px - 36px - 36px);
}

.header {
  align-items: center;
  background: var(--accent);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  padding: 24px 16px;
}

.headerIcon {
  margin-right: 16px;
}

.body {
  background: var(--white);
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 700;
  padding: 24px 16px;
}

.guide {
  color: var(--error);
  margin-bottom: 16px;
}

.guidePointList {
  margin-bottom: 16px;
}

.guidePoint {
  display: flex;
  line-height: 15px;
}

.guidePointText {
  margin-top: 5px;
}

.priceCard {
  margin-bottom: 28px;
}

.hint {
  /* maybe use --hint */
  color: #9e9e9e;
  margin-bottom: 16px;
  white-space: pre-line;
}

.confirmButton {
  background: var(--accent);
  border-radius: 10px;
  font-size: 20px;
  height: 45px;
  margin-bottom: 16px;
}

.backButton {
  /* maybe use --hint */
  color: #9e9e9e;
}
