.container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.image {
  height: 100%;
  width: 100%;
}
