.root {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  color: var(--white);
  margin-bottom: env(safe-area-inset-bottom);
  padding: 16px 24px;
}

.error {
  background: var(--snack-bar-error);
}

.success {
  background: var(--snack-bar-success);
}

.info {
  background: var(--snack-bar-info);
}
