:root {
  --primary: #06abd8;
  --primary-light: #6bc8f2;
  --primary-hover: #51c3e3;
  --primary-dark-blue: #32487b;
  --primary-dark-grey: #f5f6f8;

  --secondary-blue: #00abd8;

  --text-black: #2e2e2e;
  --accent: #ffe04a;
  --alert-red: #ff4a6b;
  --white: #ffffff;
  --error: #f44336;
  --dark-grey: #737373;
  --hint: #bdbdbd;
  --cloud-grey: #ebebeb;
  --orange: #e8b11c;

  --plan-normal: #0984c9;
  --plan-subscription: var(--accent);
  --plan-office: #9e9e9e;

  --snack-bar-error: #ec4141;
  --snack-bar-success: #699bf7;
  --snack-bar-info: #0fa958;
}
