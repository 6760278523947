.root {
  border-radius: 10px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
}

.header {
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  min-height: 50px;
  padding: 8px 16px 8px 50px;
  position: relative;
}

.noContent .header {
  border-radius: 10px;
}

.headerIcon {
  margin-left: auto;
}

.recommended {
  /* HACK: not in color var */
  background: #eb586e;
  border-radius: 11px;
  left: 0;
  line-height: 21px;
  color: var(--white);
  position: absolute;
  text-align: center;
  transform: rotate(-30deg) translate(-15%, -35%);
  top: 0;
  width: 71px;
}

.title {
  font-size: 20px;
  font-weight: 700;
}

.subscription .header {
  background: var(--plan-subscription);
  color: var(--primary-dark-blue);
}

.normal .header {
  /* maybe --plan-normal */
  background: var(--primary);
  color: var(--white);
}

.office .header {
  background: var(--plan-office);
  color: var(--white);
}

.body {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid;
  padding: 16px;
}

.subscription .body {
  border-color: var(--plan-subscription);
}

.normal .body {
  border-color: var(--primary);
}

.office .body {
  border-color: var(--plan-office);
}

.content {
  white-space: pre-line;
}

.price {
  font-size: 20px;
  font-weight: 700;
  margin-top: 16px;
  text-align: right;
}
