.page {
  padding: 24px 16px;
}

.card {
  margin-bottom: 21px;
}

.title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 16px;
}

.currentPlan {
  margin-bottom: 60px;
}

.currentPlanHint {
  color: var(--error);
  font-weight: 700;
  white-space: pre-line;
}
