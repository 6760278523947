.map {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.topArea {
  top: 26px;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
}

.banner {
  margin-bottom: 16px;
}

.spotCard {
  bottom: calc(60px + 20px + 16px + 50px);
  left: 16px;
  position: fixed;
  margin-bottom: env(safe-area-inset-bottom);
}

.bottomButton {
  border-radius: 25px;
  bottom: calc(60px + 20px);
  position: fixed;
  height: 50px;
  margin-bottom: env(safe-area-inset-bottom);
  width: 132px;
}

.rentalButton {
  left: 16px;
}

.returnButton {
  left: calc(16px + 132px + 8px);
}

.sideButton {
  position: fixed;
  margin-bottom: env(safe-area-inset-bottom);
  right: 16px;
}

.locationButton {
  bottom: calc(60px + 20px);
}

.helpButton {
  bottom: calc(60px + 20px + 16px + 55px);
}

.lastConfirmContainer {
  align-items: flex-start;
  flex-direction: column;
  display: flex;
  text-align: left;
}

.lastConfirmGroup:first-child {
  margin-bottom: 16px;
}

.lastConfirmContainerTitle {
  font-weight: 700;
}
