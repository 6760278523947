.root {
  display: flex;
  justify-content: center;
}

.dot {
  background-color: var(--primary-dark-grey);
  border-radius: 5px;
  cursor: pointer;
  margin-right: 9px;
  height: 10px;
  opacity: 0.5;
  width: 10px;
}

.dot:last-child {
  margin-right: 0;
}

.currentDot {
  opacity: 1;
}
