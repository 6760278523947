.background {
  align-items: center;
  background-image: url('./images/water-drop.jpg');
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  left: 0;
  min-height: 100vh;
  padding-bottom: env(safe-area-inset-bottom);
  position: fixed;
  top: 0;
  width: 100vw;
}
