.container {
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: center;
  width: 80px;
}

.image {
  animation: rotate 1s steps(12, end) infinite;
  transform-origin: center;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
