.page {
  padding: 24px 16px;
}

.header {
  align-self: flex-start;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 24px;
  position: relative;
}

.header::after {
  background: var(--accent);
  bottom: 0;
  content: '';
  display: block;
  left: 6px;
  position: absolute;
  height: 10px;
  width: 100%;
  z-index: -1;
}

.middleHeader {
  margin-bottom: 0;
  margin-top: 120px;
}

.text {
  margin-bottom: 16px;
  white-space: pre-line;
}

.helpStep {
  margin-bottom: 16px;
}

.step {
  align-items: center;
  display: flex;
  font-size: 22px;
}

.stepCurrent {
  font-size: 26px;
}

.stepMax {
  font-size: 18px;
  margin-right: 18px;
}

.stepText {
  white-space: pre-line;
}

.helpImageContainer {
  align-items: flex-start;
  flex-wrap: wrap;
  display: flex;
}

.helpImageArrow {
  align-self: center;
  color: var(--primary);
  flex-shrink: 0;
  margin: 0 -16px;
  margin-left: -46px;
}

.helpImage {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin-bottom: 34px;
  width: calc((100% - 30px) / 3);
}

.helpImage:nth-child(2n + 1) {
  margin-right: 30px;
}

.helpImage:last-child {
  margin-right: 0;
}

.helpImageText {
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 12px;
  white-space: pre-line;
}

.helpImageTextError {
  color: var(--error);
}

.helpImageTextIcon {
  margin-right: 4px;
}

.helpImageEntity {
  width: 100%;
}

.help44 {
  align-self: stretch;
}

.subHeader {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: 16px;
}
