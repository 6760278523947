.pin {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -100%);
}

.currentDot {
  background: var(--primary);
  border: 2px solid var(--white);
  border-radius: 50%;
  height: 20px;
  width: 20px;
}
