.page {
  max-width: 100vw;
}

.iframe {
  border: none;
  flex-grow: 1;
}

.bottomTab {
  position: relative;
}
