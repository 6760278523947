.background {
  align-items: center;
  background-image: url('./images/water-drop.jpg');
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  left: 0;
  min-height: 100vh;
  padding-bottom: env(safe-area-inset-bottom);
  position: fixed;
  top: 0;
  width: 100vw;
}

.backgroundWhite {
  background: var(--white);
}

.closeButton {
  color: var(--white);
  left: 16px;
  position: absolute;
  top: 16px;
  z-index: 1;
}

.closeButtonWhite {
  color: var(--primary-dark-blue);
}

.container {
  background: var(--white);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  min-height: 420px;
  padding: 25px 15px 15px;
  width: calc(375px - 41px - 41px);
}

.title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 40px;
  position: relative;
}

.title::after {
  background: #c4c4c4;
  bottom: -4px;
  content: '';
  display: block;
  left: 0;
  position: absolute;
  height: 4px;
  width: 52px;
}

.itemHeader {
  align-items: center;
  display: flex;
}

.itemRound {
  align-items: center;
  background: var(--hint);
  border-radius: 50%;
  color: var(--white);
  display: flex;
  height: 24px;
  justify-content: center;
  margin-right: 8px;
  width: 24px;
}

.itemRoundSuccess {
  background: var(--primary);
}

.itemName {
  font-weight: 700;
}

.itemBody {
  display: flex;
  margin: 8px 0;
  min-height: 24px;
}

.itemHintContainer {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  margin-right: 8px;
  width: 24px;
}

.itemHintLine {
  background: var(--hint);
  height: 100%;
  width: 1px;
}

.itemHint {
  padding-bottom: 40px;
  white-space: pre-line;
}

.cancelButton {
  background: var(--primary);
  border-radius: 21px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  color: var(--white);
  font-weight: 700;
  margin: 0 auto;
  margin-top: auto;
  height: 42px;
  width: 262px;
}

.takingContainer {
  background: var(--white);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  width: calc(375px - 41px - 41px);
}

.takingHint {
  font-size: 14px;
  padding: 20px 20px 14px;
  white-space: pre-line;
}

.takingHint strong {
  font-size: 20px;
}
