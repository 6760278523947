.container {
  align-items: center;
  display: flex;
  height: 60px;
  overflow: auto;
  scroll-snap-type: x mandatory;
  width: calc(375px - 16px - 16px);
}

.image {
  scroll-snap-align: start;
  width: calc(375px - 16px - 16px);
}

.container::-webkit-scrollbar {
  display: none;
}
