.header {
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 15px;
  position: relative;
}

.headerWithImage {
  color: var(--white);
  height: 270px;
}

.title {
  font-size: 24px;
}

.subtitle {
  font-size: 12px;
  margin-top: 10px;
}

.image {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.content {
  padding: 16px 8px;
  white-space: pre-line;
}

.content img {
  width: 100%;
}

.button {
  background: var(--primary);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
  color: var(--white);
  font-weight: 700;
  height: 50px;
  margin: 24px 8px;
}
