.root {
  align-items: center;
  display: inline-flex;
  height: 18px;
  justify-content: center;
  position: relative;
  vertical-align: middle;
  width: 18px;
}

.checked {
  background: var(--primary);
  border-radius: 1px;
  color: var(--white);
}

.unCheckedBorder {
  border: 2px solid #dadada;
  border-radius: 1px;
  height: 100%;
  width: 100%;
}

.icon {
  flex-shrink: 0;
}

.input {
  display: none;
}
