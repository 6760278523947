.root {
  color: var(--primary-dark-blue);
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 1.448;
  min-height: 100vh;
}

.snackBar {
  bottom: 0;
  position: fixed;
  width: 100%;
}

.snackBarWithBottomTab {
  bottom: 60px;
}

.bottomTab {
  bottom: 0;
  left: 0;
  margin-top: auto;
  position: fixed;
  width: 100%;
}

.bottomTabHolder {
  height: 60px;
  margin-top: auto;
  margin-bottom: env(safe-area-inset-bottom);
}
