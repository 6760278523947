.button {
  align-items: center;
  background: var(--white);
  border-radius: 50%;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  color: var(--primary);
  display: flex;
  flex-direction: column;
  font-size: 11px;
  font-weight: 700;
  line-height: 17px;
  height: 55px;
  justify-content: center;
  width: 55px;
}
