.menu {
  display: flex;
  flex-direction: column;
}

.item {
  display: flex;
  min-height: 60px;
  padding-top: 8px;
  padding-bottom: 10px;
}

.largeItem {
  padding-top: 18px;
}

.borderItem {
  border-bottom: 0.5px solid rgba(112, 112, 112, 0.2);
}

.itemIcon {
  margin-right: 18px;
}

.text {
  font-weight: 700;
  line-height: 20px;
}

.hint {
  font-size: 12px;
  line-height: 17px;
  margin-top: 5px;
}

.itemArrow {
  margin-left: auto;
}
