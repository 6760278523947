.container {
  align-items: stretch;
  border-radius: 15px;
  display: flex;
  height: 60px;
}

.subscription {
  border: 2px solid var(--accent);
}

.normal {
  border: 2px solid var(--plan-normal);
}

.hint {
  align-items: center;
  border-radius: 13px 0 0 13px;
  display: flex;
  font-size: 20px;
  font-weight: 700;
  justify-content: center;
  width: 110px;
}

.subscription .hint {
  background: var(--accent);
}

.normal .hint {
  background: var(--plan-normal);
  color: var(--white);
}

.valueContainer {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin-right: 28px;
}

.value {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
}

.tax {
  font-size: 12px;
  line-height: 17px;
  margin-top: -1px;
  font-weight: 400;
}
