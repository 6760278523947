.container {
  display: flex;
  flex-direction: column;
  padding-bottom: env(safe-area-inset-bottom);
}

.header {
  background: linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5));
  color: var(--white);
  display: flex;
  flex-direction: column;
  height: 250px;
  padding: 20px;
  position: relative;
}

.headerWithoutImage {
  color: var(--primary-dark-blue);
}

.imageContainer {
  height: 100%;
  display: flex;
  left: 0;
  overflow: auto;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.imageContainer::-webkit-scrollbar {
  display: none;
}

.image {
  scroll-snap-align: start;
  flex-shrink: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.headerInfo {
  align-items: center;
  display: flex;
}

.subCategory {
  font-size: 12px;
}

.count {
  align-items: center;
  display: flex;
  font-weight: 700;
  margin-left: auto;
  margin-top: 10px;
}

.name {
  margin-top: auto;
  font-size: 20px;
  font-weight: 700;
}

.introduction {
  padding: 20px;
  margin-bottom: 10px;
}

.menu {
  margin: 10px 12px;
}

.googleMapButton {
  display: block;
  margin-top: 17px;
  text-decoration: underline;
}

.copyButton {
  display: flex;
  margin-left: 18px;
}
