.loading {
  align-items: center;
  background: rgba(17, 17, 17, 0.7);
  border-radius: 5px;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  max-width: 260px;
  padding: 10px 15px;
  white-space: pre-line;
  word-break: break-all;
  text-align: center;
}

.loadingIcon {
  margin: -10px 0;
  transform: scale(0.5);
}
