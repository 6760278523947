.page {
  padding: 24px 16px;
}

.waterDropImage {
  align-self: center;
  margin-bottom: 8px;
  width: 28px;
}

.title {
  align-self: center;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
  text-align: center;
  white-space: pre-line;
}

.reason {
  align-self: center;
  margin-bottom: 56px;
}

.hr {
  align-items: center;
  display: flex;
  margin-bottom: 40px;
}

.hrLine {
  background: var(--text-black);
  flex-grow: 1;
  height: 2px;
}

.detail {
  white-space: pre-line;
}

.tryButton {
  align-self: center;
  background: var(--white);
  border: 1px solid var(--secondary-blue);
  border-radius: 19px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  color: var(--secondary-blue);
  font-weight: 700;
  margin-top: 40px;
  height: 38px;
  width: 271px;
}

.bottomButtonContainer {
  align-items: center;
  align-self: center;
  display: flex;
  margin-top: 24px;
}

.plainButton {
  font-weight: 700;
  height: 40px;
  width: 80px;
}

.plainButton:first-child {
  margin-right: 36px;
}
