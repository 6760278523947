.root {
  background: var(--white);
  box-shadow: 0px 1px 4px rgba(50, 72, 123, 0.2);
  display: flex;
  justify-content: center;
  padding-bottom: env(safe-area-inset-bottom);
}

.tabItem {
  align-items: center;
  color: var(--dark-grey);
  display: flex;
  flex-direction: column;
  font-size: 10px;
  height: 60px;
  justify-content: center;
  width: calc(75px + 36px);
}

.currentTab {
  color: var(--primary);
}

.tabText {
  transition: transform 0.3s;
}

.currentTab .tabText {
  transform: scale(1.1);
}
