.button {
  align-items: center;
  border-radius: 21px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  display: flex;
  font-weight: 700;
  justify-content: center;
  height: 42px;
}

.white {
  background: var(--white);
  color: var(--primary-dark-blue);
}

.primary {
  background: var(--primary);
  color: var(--white);
}

.primaryDarkBlue {
  background: var(--primary-dark-blue);
  color: var(--white);
}

.icon {
  margin-right: 10px;
}
