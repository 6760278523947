.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-height: 70vh;
}

.imageContainer {
  margin-bottom: auto;
}

.image {
  display: block;
  max-width: calc(375px - 16px - 16px);
}

.button {
  background: var(--white);
  border: 1px solid var(--primary-dark-blue);
  border-radius: 19px;
  color: var(--primary-dark-blue);
  font-weight: 700;
  flex-shrink: 0;
  height: 38px;
  margin-top: 48px;
  padding: 0 20px;
}
