.page {
  padding: 24px 16px;
}

.title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

.hint {
  margin-bottom: 20px;
  white-space: pre-line;
}

.profileButton {
  color: var(--primary);
  margin-top: auto;
  margin-bottom: env(safe-area-inset-bottom);
  font-size: 18px;
  font-weight: 700;
}

.price {
  margin-bottom: 30px;
}

.noRenting {
  align-items: center;
  background: var(--primary);
  border-radius: 10px;
  color: var(--white);
  display: flex;
  font-weight: 700;
  height: 70px;
  justify-content: center;
  margin-bottom: 16px;
}

.kasa {
  align-items: center;
  background: var(--primary);
  border-radius: 28px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  color: var(--white);
  display: flex;
  font-weight: 700;
  height: 56px;
  margin-bottom: 20px;
  justify-content: center;
  padding: 0 16px;
}

.info {
  align-items: flex-end;
  display: flex;
  margin-left: auto;
  flex-direction: column;
}

.pin {
  align-items: center;
  display: flex;
}

.pinIcon {
  margin-right: 7px;
}

.rightArrow {
  margin-left: 10px;
}
