.page {
  padding: 24px 16px;
}

.hint {
  margin-bottom: 40px;
}

.inputTitle {
  font-weight: 700;
  margin-bottom: 9px;
}

.input {
  border: 1px solid #c2c2c2;
  border-radius: 25px;
  height: 50px;
  display: flex;
  padding: 0 16px;
}

.button {
  background: var(--primary);
  border-radius: 21px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  color: var(--white);
  font-weight: 700;
  margin: 0 auto;
  margin-top: 48px;
  height: 42px;
  width: 262px;
}
